import mainjson from './main';
import { Level } from '../utils/LoginFunctions';
import { Resend } from '../utils/ApiFunctions';


async function reSend(id){
    
    let result =  await Resend(id);
    if (result.error == 0) {
        alert("Email verzonden");
    }
    else {
        alert("Email niet verzonden");
    }
}

export default function GetData() {
        let data = {
        "apitype":"dateorders",
        "id":"order_id",
        "options":["edit"],
        "nameSingle":"order",
        "subidtext":"Order [!order_id!] - [!user_name!], ",
        "nameMultiple":"orders",
        "extraoptions":[
            {
                "name":"Resend e-mail",
                "method":reSend,
            },
            {
                "name":"Tickets",
                "page":"/ordertickets",
            },
        ],
        "fields":{
            "field1":{
                "name":"ID",
                "field":"order_id",
                "type":"Display",
                "list":true,
            },
            "field2":{
                "name":"Tickets",
                "field":"count",
                "type":"Display",
                "list":true,
            },
            "field2b":{
                "name":"Tickets",
                "field":"tickets",
                "type":"DisplayOrder",
                "list":false,
            },
            
            "field3":{
                "name":"Date",
                "field":"order_date",
                "type":"DateTimeInput",
                "required":true,
                "list":true,
                "displayEdit":true
            },
            "field4":{
                "name":"Client",
                "field":"user_name",
                "type":"Display",
                "list":true,
            },    
            "field5":{
                "name":"Client e-mail",
                "field":"user_email",
                "type":"Display",
                "list":true,
            },            
        }
    }

    return data;
}